<template>
  <div>
    <h3 ref="what">
      Bar Chart
    </h3>
    <p class="mt-2">
      A bar chart is commonly used to represent the grouping of data into different categories.
      The categories could be something like an age group, months of the year, or geographical locations.
      In a bar chart (also known as a bar graph), the data is represented using a number of bars. Each bar represents a particular category or group.
      The height of a bar is proportional to the sum total of entries in the category it represents.
      <br>
    </p>
    <h3 ref="example">
      Example of a Bar Chart
    </h3>
    <p class="mt-2">
      Imagine you are at a dog show. The show features dogs of a variety of breeds, e.g., 2 Bulldogs, 3 Dobermans, 4 Greyhounds, 1 Dalmatian, and 2 Shar-Peis.
      Below is a bar chart summarizing this data graphically. Shown on the horizontal axis is the breed of dogs.
      Shown on the vertical axis is number of dogs.
      Each bar represents a dog breed and the height of the bar is equal to the number of dogs of the breed that it represents.
      For example, the bar representing Dobermans has a height of 3 units, while the bar representing Greyhounds is 4 units tall.
    </p>
    <h3 ref="pg">
      MagicGraph | Test your knowledge of bar charts
    </h3>
    <br>
    <h5> Getting Started </h5>
    <p>
      Your goal is to create a bar chart that represents the number of dogs of different breeds. Drag and adjust the height of each bar until it matches the number of that breed of dog.
      <br>
    </p>
    <h5> Icons on the MagicGraph </h5>
    <p>
      Tap on <i class="shuffler ma-1" /> button to shuffle.
      Tap on <i class="tester ma-1" /> button to test your answer.
      Tap on <i class="undoer ma-1" /> button to go to the previous step.
    </p>
    <br>
    <v-responsive>
      <v-layout justify-center>
        <div id="jxgbox1" class="edliy-box-about" />
      </v-layout>
    </v-responsive>
  </div>
</template>
<script>
import Boxes from './Boxes.js'
export default {
  name: 'BarChart',
  created () {
    this.$store.commit('navigation/resetState');
    let title = 'Bar Chart';
    this.$store.commit('navigation/changeTitle', title);
    this.$store.commit('navigation/changeMenu', title);
    /*let newTopics = [
      {title: 'Parallel Lines', img:'/assets/number-1.svg', action: () => this.goto('intro')},
      {title: 'Transversal', img:'/assets/number-2.svg', action: () => this.goto('trans')},
      {title: 'Pair of Angles', img:'/assets/number-3.svg', action: () => this.goto('angles')},
      {title: 'MagicGraph',img:'/assets/touch.svg', action: () => this.goto('pg')},
    ];
    this.$store.commit('navigation/replaceTopics', newTopics);*/
    let newshowhome = false;
    this.$store.commit('navigation/toggleshowhome', newshowhome);
    let newMath =true;
    this.$store.commit('navigation/replaceMath', newMath);
    let newLeftArrow =true;
    this.$store.commit('navigation/replaceLeftArrow', newLeftArrow);
    let newModule=true;
    this.$store.commit('navigation/replaceModule', newModule);
  },
  mounted () {
    MathJax.Hub.Queue(["Typeset", MathJax.Hub]);
    Boxes.box2();
  },
  metaInfo() {
  return{ title: 'Bar Chart',
        //  titleTemplate: '%s - Learn interactively',
          meta: [ {name: 'viewport', content: 'width=device-width, initial-scale=1'},
                  {vmid:'description',name: 'description', content: 'Learn interactively about bar charts'}
                ]
        }
   },
}
</script>
<style lang="scss">
@import 'src/styles/edliy-box.scss';
@import 'src/styles/subtopic-menu.scss';
@import 'src/styles/edliy-box-about.scss';
@import 'src/styles/screen-sizes.scss';
</style>
