import {
  makeResponsive,
  placeTitle,
  placeImage,
  placeInput,
  placeSlider,
  hoverMe,
  placeRec,
  hiddenPt,
  fixedPt,
  clearInputFields,
  dragMe,
  placeArrow,
  placeGravity,
  placeText,
  placeMiddleText,
  placeLine,
  placePoint,
  placeGlider,
  placeRuler,
  placeLeftText,
  placeCircle,
  placeAngle,
  placeDash,
  placeLabel,
  placeRightText,
//placePoint(board, positionX, positionY, size, cols1, cols2)
//placeDash(board, Pt1, Pt2, width, cols)
  placeArc,
  placeLogo,
  placeErase,
  placeShuffle,
  placeTest,
  placeSeg,
  setConfig,
  placeStat,
  placeTri,
  shuffle,
  placeEraseLeft,
  placeTapeHori,
  placeTapeVert,
  placeSq,
  placeBoldText,
  placeRedo,
  placeUndo,
  placeBCircles,
  placeCircles,
  placeChecks,
  placeCross,
  placeWhite,
  createSpace,
  createAxes,
  placeStartOver
} from '../Utils';
const Boxes = {
    box2: function () {
  	JXG.Options.board.minimizeReflow = 'none';
    var brd2 = createSpace(-1, 11, -1, 11);
    var ax = createAxes(brd2);
    placeLogo(brd2);
    makeResponsive(brd2);
    //var bck =placeWhite(brd2);
    //bck.setAttribute({opacity:0});
    placeTitle(brd2,'Bar Chart', '');
    var redo =placeStartOver(brd2);
    redo.setAttribute({visible:false});
    var show =placeBCircles(brd2);
    var hide=placeCircles(brd2);
    var checks = placeChecks(brd2);
    var cross = placeCross(brd2);
    hide[0].setAttribute({visible:false});
    brd2.options.layer['image'] =14;
    brd2.options.image.highlight=false;
    brd2.options.point.highlight=false;
    brd2.options.point.showInfoBox=false;
    /////////////////////////////////////////////////
    const boundingBox = brd2.attr.boundingbox;
    const positionX = (boundingBox[2]+boundingBox[0])/2;
    const positionY = (boundingBox[1]+boundingBox[3])/2;
    const height = (boundingBox[1]-boundingBox[3])/2;
    console.log(height);
    ///////////////////////////////////////////////////////
    var type1 = 2;
    var type2 = 3;
    var type3 = 4
    var type4 = 1
    var type5 = 2;
    var dataArr=[type1, type2, type3, type4, type5];
    var bulldog=[];
    var doberman=[];
    var greyhound=[];
    var dalmatian=[];
    var sharpei =[];
    var charts=[];
    var a=1;
  //  var i=1, j=1, k=1, l=1, m=1;
    var ini =function(){
    brd2.removeObject(bulldog);
    brd2.removeObject(doberman);
    brd2.removeObject(greyhound);
    brd2.removeObject(dalmatian);
    brd2.removeObject(sharpei);
    brd2.removeObject(charts);
    }
    var bd = function(){
    for(let i=1;i<=type1;i++)
     {
     bulldog[i-1] =brd2.create('image', ['/assets/bulldog.svg', [0.55, 9.25-a*(i-1)],[0.9, 0.9]], {opacity:1, fixed:true});
     bulldog[i-1].setLabel('Bulldog')
     bulldog[i-1].label.setAttribute({visible:false, offset:[0, -15], CssStyle:'fontFamily:Oswald', fontSize:12});
     bulldog[i-1].on('over', function () {this.label.setAttribute({visible:true});});
     bulldog[i-1].on('out', function () {this.label.setAttribute({visible:false});});
     }
    }
    var dm = function(){
    for(let j=1;j<=type2;j++)
     {
     doberman[j-1] =brd2.create('image', ['/assets/doberman.svg', [1.55, 9.25-a*(j-1)],[0.9, 0.9]], {opacity:1, fixed:true});
     doberman[j-1].setLabel('Doberman')
     doberman[j-1].label.setAttribute({visible:false, offset:[0, -15], CssStyle:'fontFamily:Oswald', fontSize:12});
     doberman[j-1].on('over', function () {this.label.setAttribute({visible:true});});
     doberman[j-1].on('out', function () {this.label.setAttribute({visible:false});});
     }
    }
    var gh = function(){
    for(let k=1;k<=type3;k++)
     {
     greyhound[k-1] =brd2.create('image', ['/assets/greyhound.svg', [2.55, 9.25-a*(k-1)],[0.9, 0.9]], {opacity:1, fixed:true});
     greyhound[k-1].setLabel('Greyhound')
     greyhound[k-1].label.setAttribute({visible:false, offset:[0, -15], CssStyle:'fontFamily:Oswald', fontSize:12});
     greyhound[k-1].on('over', function () {this.label.setAttribute({visible:true});});
     greyhound[k-1].on('out', function () {this.label.setAttribute({visible:false});});
     }
    }
    var dmt = function(){
    for(let l=1;l<=type4;l++)
     {
     dalmatian[l-1] =brd2.create('image', ['/assets/dalmatian.svg', [3.55, 9.25-a*(l-1)],[0.9, 0.9]], {opacity:1, fixed:true});
     dalmatian[l-1].setLabel('Dalmatian')
     dalmatian[l-1].label.setAttribute({visible:false, offset:[0, -15], CssStyle:'fontFamily:Oswald', fontSize:12});
     dalmatian[l-1].on('over', function () {this.label.setAttribute({visible:true});});
     dalmatian[l-1].on('out', function () {this.label.setAttribute({visible:false});});
     }
    }
    var sp = function(){
    for(let m=1;m<=type5;m++)
     {
     sharpei[m-1] =brd2.create('image', ['/assets/shar-pei.svg', [4.55, 9.25-a*(m-1)],[0.9, 0.9]], {opacity:1, fixed:true});
     sharpei[m-1].setLabel('Shar-pei')
     sharpei[m-1].label.setAttribute({visible:false, offset:[0, -15], CssStyle:'fontFamily:Oswald', fontSize:12});
     sharpei[m-1].on('over', function () {this.label.setAttribute({visible:true});});
     sharpei[m-1].on('out', function () {this.label.setAttribute({visible:false});});
     }
    }
    var Pt=[];
    var Pt0 =brd2.create('point', [1, 0],{name:'Bulldog', label:{anchorX:'middle',CssStyle:'fontFamily:Oswald',offset:[0, 15], fontSize:function(){return Math.round(16*brd2.canvasWidth/800.)}}, snapToGrid:true, strokeColor:'black', fillColor:'yellow'});

    var Pt1 =brd2.create('point', [2, 0],{name:'Doberman', label:{anchorX:'middle',CssStyle:'fontFamily:Oswald',offset:[0, 15], fontSize:function(){return Math.round(16*brd2.canvasWidth/800.)}}, snapToGrid:true, strokeColor:'black', fillColor:'yellow'});
    var Pt2 =brd2.create('point', [3, 0],{name:'Greyhound', label:{anchorX:'middle',CssStyle:'fontFamily:Oswald',offset:[0, 15], fontSize:function(){return Math.round(16*brd2.canvasWidth/800.)}}, snapToGrid:true, strokeColor:'black', fillColor:'yellow'});
    var Pt3 =brd2.create('point', [4, 0],{name:'Dalmatian', label:{anchorX:'middle',CssStyle:'fontFamily:Oswald',offset:[0, 15], fontSize:function(){return Math.round(16*brd2.canvasWidth/800.)}}, snapToGrid:true, strokeColor:'black', fillColor:'yellow'});
    var Pt4 =brd2.create('point', [5, 0],{name:'Shar-pei', label:{anchorX:'middle',CssStyle:'fontFamily:Oswald',offset:[0, 15], fontSize:function(){return Math.round(16*brd2.canvasWidth/800.)}}, snapToGrid:true, strokeColor:'black', fillColor:'yellow'});
    var f=[function(){return (Pt0.Y()).toFixed(2);}, function(){return (Pt1.Y()).toFixed(2);}, function(){return (Pt2.Y()).toFixed(2);}, function(){return (Pt3.Y()).toFixed(2);}, function(){return (Pt4.Y()).toFixed(2);}];
    //var f =[Pt0.Y(), 5, 5, 5, 5];
    brd2.create('chart', [f], {fillOpacity:1, anchorX:'left',chartStyle:'bar',width:1,dir:'vertical'});
    var cht = function(){
      charts[0] =brd2.create('chart', dataArr, {fillOpacity:1, anchorX:'left',chartStyle:'bar',labels:dataArr,width:1,dir:'vertical'});
    }
    bd(); dm();gh();dmt();sp();
    //var test = brd2.create('image', ['/assets/puzzle.svg', [positionX+height-1.6,positionY-0.5-1.2],[1.2, 1.2]], {opacity:1, fixed:true});
    var test = placeTest(brd2, 'bottom');
    test.setLabel('Tap to Test')
    test.label.setAttribute({visible:false, offset:[0, -15], CssStyle:'fontFamily:Oswald', fontSize:12});
    test.on('over', function () {this.label.setAttribute({visible:true});});
    test.on('out', function () {this.label.setAttribute({visible:false});});
    var correct=brd2.create('image', ['/assets/check.svg', [8.5, 2], [1. ,1.]], {visible: false, fixed: true});
    var wrong=brd2.create('image', ['/assets/cross.svg', [8.5, 2], [1. ,1.]], {visible: false, fixed: true});
    function check(){
        if(Pt0.Y() == type1 && Pt1.Y() == type2 && Pt2.Y() == type3 && Pt3.Y() == type4 && Pt4.Y() == type5){
            return 1;
        }
        else{
            return 0;
        }
}
   test.on('down', check);
    //var reload = brd2.create('image', ['/assets/shuffle.svg', [positionX+height-1.6,positionY+0.5],[1.2, 1.2]], {opacity:1, fixed:true});
    var reload=placeShuffle(brd2, 'top');
    reload.setLabel('Tap to Shuffle')
    reload.label.setAttribute({visible:false, offset:[0, -15], CssStyle:'fontFamily:Oswald', fontSize:12});
    reload.on('over', function () {this.label.setAttribute({visible:true});});
    reload.on('out', function () {this.label.setAttribute({visible:false});});
    var n=0;
    reload.on('down', function(){
      if(n==4){
        checks[n].setAttribute({visible:check()==1});
        cross[n].setAttribute({visible:check()==0});
        redo.setAttribute({visible:true});
        //bck.setAttribute({opacity:1});
      }
      if(n<4){
        checks[n].setAttribute({visible:check()==1});
        cross[n].setAttribute({visible:check()==0});
        ini();
        type1=Math.round(Math.random()*3)+1;
        type2=Math.round(Math.random()*4)+1;
        type3=Math.round(Math.random()*4)+1;
        type4=Math.round(Math.random()*2)+1;
        type5=Math.round(Math.random()*3)+1;
        bd();dm();gh();dmt();sp();
        n = n+1;
        hide[n].setAttribute({visible:false});
      }

    });
    brd2.create('text', [9, -0.5, 'Breed of Dog'],{display:'internal',anchorX:'middle', fixed:true, CssStyle:'fontFamily:Oswald', fontSize:function(){return Math.round(22*brd2.canvasWidth/800.)}});
    brd2.create('text', [-0.5, 5, 'Number of Dogs'],{display:'internal', rotate:90, anchorX:'middle', fixed:true, CssStyle:'fontFamily:Oswald', fontSize:function(){return Math.round(22*brd2.canvasWidth/800.)}});
    //brd2.create('text', [1, -0.5, 'Bulldog'],{display:'internal',anchorX:'middle', fixed:true, CssStyle:'fontFamily:Oswald', fontSize:function(){return Math.round(16*brd2.canvasWidth/800.)}});
    //brd2.create('text', [2, -0.5, 'Doberman'],{display:'internal',anchorX:'middle', fixed:true, CssStyle:'fontFamily:Oswald', fontSize:function(){return Math.round(16*brd2.canvasWidth/800.)}});
    //brd2.create('text', [3, -0.5, 'Greyhound'],{display:'internal',anchorX:'middle', fixed:true, CssStyle:'fontFamily:Oswald', fontSize:function(){return Math.round(16*brd2.canvasWidth/800.)}});
    //brd2.create('text', [4, -0.5, 'Dalmatian'],{display:'internal',anchorX:'middle', fixed:true, CssStyle:'fontFamily:Oswald', fontSize:function(){return Math.round(16*brd2.canvasWidth/800.)}});
    //brd2.create('text', [5, -0.5, 'Shar-pei'],{display:'internal',anchorX:'middle', fixed:true, CssStyle:'fontFamily:Oswald', fontSize:function(){return Math.round(16*brd2.canvasWidth/800.)}});

    /*var dice = function(){
    for (k=1; k<=cnt; k++){
    brd2.create('image', ['/assets/dice-'+tup[k-1][0]+'.svg', [12, 16-3*k],[4, 4]], {name:img[2*(k-1)+1], fixed:true});
    brd2.create('image', ['/assets/dice-'+tup[k-1][1]+'.svg', [17.5,16-3*k],[4, 4]],{name:img[2*(k-1)+2], rotate:50+20*Math.random(), fixed:true});
    brd2.update();
    };
  };
  	//
  	var plus1=brd1.create('image', ['/assets/bulldog.svg', [-14., 20.-a],[1.6, 1.7]], {opacity:1, fixed:true});
  	plus1.setLabel('Bulldog')
  	plus1.label.setAttribute({visible:false, offset:[0, -15], CssStyle:'fontFamily:Oswald', fontSize:12});
  	plus1.on('over', function () {this.label.setAttribute({visible:true});});
  	plus1.on('out', function () {this.label.setAttribute({visible:false});});
  	//
  	var plus2=brd1.create('image', ['/assets/dachshund.svg', [-12., 20.-a-0.1],[1.75, 1.75]], {opacity:1, fixed:true});
  	plus2.setLabel('Corgi')
  	plus2.label.setAttribute({visible:false, offset:[0, -15], CssStyle:'fontFamily:Oswald', fontSize:12});
  	plus2.on('over', function () {this.label.setAttribute({visible:true});});
  	plus2.on('out', function () {this.label.setAttribute({visible:false});});
  	//
  	var plus3=brd1.create('image', ['/assets/mastiff.svg', [-10, 20-a-0.1],[3, 3.2]], {opacity:1, fixed:true});
  	plus3.setLabel('Mastiff')
  	plus3.label.setAttribute({visible:false, offset:[0, -15], CssStyle:'fontFamily:Oswald', fontSize:12});
  	plus3.on('over', function () {this.label.setAttribute({visible:true});});
  	plus3.on('out', function () {this.label.setAttribute({visible:false});});
      //
  	var plus4=brd1.create('image', ['/assets/pointer.svg', [-7.5, 20-a-0.1],[2.5, 2.5]], {opacity:1, fixed:true});
  	plus4.setLabel('Pointer')
  	plus4.label.setAttribute({visible:false, offset:[0, -15], CssStyle:'fontFamily:Oswald', fontSize:12});
  	plus4.on('over', function () {this.label.setAttribute({visible:true});});
  	plus4.on('out', function () {this.label.setAttribute({visible:false});});
      //
  	var plus5=brd1.create('image', ['/assets/doberman.svg', [-5, 20.15-a],[2.5, 2.5]], {opacity:1, fixed:true});
  	plus5.setLabel('Doberman')
  	plus5.label.setAttribute({visible:false, offset:[0, -15], CssStyle:'fontFamily:Oswald', fontSize:12});
  	plus5.on('over', function () {this.label.setAttribute({visible:true});});
  	plus5.on('out', function () {this.label.setAttribute({visible:false});});
      //
  	var plus6=brd1.create('image', ['/assets/greyhound.svg', [-2.5, 20-a-0.1],[2.85, 2.85]], {opacity:1, fixed:true});
  	plus6.setLabel('Greyhound')
  	plus6.label.setAttribute({visible:false, offset:[0, -15], CssStyle:'fontFamily:Oswald', fontSize:12});
  	plus6.on('over', function () {this.label.setAttribute({visible:true});});
  	plus6.on('out', function () {this.label.setAttribute({visible:false});});
      //
  	var plus7=brd1.create('image', ['/assets/dalmatian.svg', [0, 20-0.2-a],[2.35, 2.6]], {opacity:1, fixed:true});
  	plus7.setLabel('Dalmatian')
  	plus7.label.setAttribute({visible:false, offset:[0, -15], CssStyle:'fontFamily:Oswald', fontSize:12});
  	plus7.on('over', function () {this.label.setAttribute({visible:true});});
  	plus7.on('out', function () {this.label.setAttribute({visible:false});});
      //
  	var plus8=brd1.create('image', ['/assets/shar-pei.svg', [2.5, 20-a-0.1],[2.25, 2.25]], {opacity:1, fixed:true});
  	plus8.setLabel('Shar-Pei')
  	plus8.label.setAttribute({visible:false, offset:[0, -15], CssStyle:'fontFamily:Oswald', fontSize:12});
  	plus8.on('over', function () {this.label.setAttribute({visible:true});});
  	plus8.on('out', function () {this.label.setAttribute({visible:false});});
      //
  	var plus9 = brd1.create('image', ['/assets/rottweiler.svg', [5, 20-a-0.1],[2.5, 2.5]], {opacity:1, fixed:true});
  	plus9.setLabel('Rottweiler')
  	plus9.label.setAttribute({visible:false, offset:[0, -15], CssStyle:'fontFamily:Oswald', fontSize:12});
  	plus9.on('over', function () {this.label.setAttribute({visible:true});});
  	plus9.on('out', function () {this.label.setAttribute({visible:false});});
      //
  	var plus10 =brd1.create('image', ['/assets/chihuahua.svg', [7.5, 20.25-a],[1.15, 1.]], {opacity:1, fixed:true});
  	plus10.setLabel('Chihuahua')
  	plus10.label.setAttribute({visible:false, offset:[0, -15], CssStyle:'fontFamily:Oswald', fontSize:12});
  	plus10.on('over', function () {this.label.setAttribute({visible:true});});
  	plus10.on('out', function () {this.label.setAttribute({visible:false});});
  	//
  	var v =[0,0,0,0,0];
  	var i=0;
  	plus1.on('down', function(){if(i<=4){plus1.moveTo([-14+2.75*i+0.5,-a]); v[i]=14/12; i+=1;}else{return;}});
  	plus2.on('down', function(){if(i<=4){plus2.moveTo([-14+2.75*i+0.5,-a-0.1]); v[i]=12/12; i+=1;}else{return;}});
  	plus3.on('down', function(){if(i<=4){plus3.moveTo([-14+2.75*i,-a-0.1]); v[i]=30/12; i+=1;}else{return;}});
  	plus4.on('down', function(){if(i<=4){plus4.moveTo([-14+2.75*i,-a-0.1]); v[i]=24/12; i+=1;}else{return;}});
  	plus5.on('down', function(){if(i<=4){plus5.moveTo([-14+2.75*i,0.15-a]);v[i]=28/12;  i+=1;}else{return;}});
  	plus6.on('down', function(){if(i<=4){plus6.moveTo([-14+2.75*i,-a]); v[i]=27/12; i+=1;}else{return;}});
  	plus7.on('down', function(){if(i<=4){plus7.moveTo([-14+2.75*i,-0.1-a]); v[i]=22/12; i+=1;}else{return;}});
  	plus8.on('down', function(){if(i<=4){plus8.moveTo([-14+2.75*i,-a]);v[i]=20/12;  i+=1;}else{return;}});
  	plus9.on('down', function(){if(i<=4){plus9.moveTo([-14+2.75*i,-a]); v[i]=2; i+=1;}else{return;}});
  	plus10.on('down',function(){if(i<=4){plus10.moveTo([-14+2.75*i+0.5,0.25-a]);v[i]=11/12;  i+=1;}else{return;}});
  ///////////////////////////////////////////////////////////
  	var mu = function(){return (v[0]+v[1]+v[2]+v[3]+v[4])/(i+0.00001)};
  	var sig = function(){
  		if(i==1){return Math.sqrt((v[0]-mu())*(v[0]-mu()))}
  		else if(i==2){return Math.sqrt(((v[0]-mu())*(v[0]-mu())+(v[1]-mu())*(v[1]-mu()))/2)}
  		else if(i==3){return Math.sqrt(((v[0]-mu())*(v[0]-mu())+(v[1]-mu())*(v[1]-mu())+ (v[2]-mu())*(v[2]-mu()))/3)}
  		else if(i==4){return Math.sqrt(((v[0]-mu())*(v[0]-mu())+(v[1]-mu())*(v[1]-mu())+(v[2]-mu())*(v[2]-mu())+ (v[3]-mu())*(v[3]-mu()))/4)}
  		else if(i==5){return Math.sqrt(((v[0]-mu())*(v[0]-mu())+(v[1]-mu())*(v[1]-mu())+(v[2]-mu())*(v[2]-mu())+(v[3]-mu())*(v[3]-mu())+(v[4]-mu())*(v[4]-mu()))/5)}
  		else{return 0;}
  	};
  	brd1.create('text', [-17.7, -1.5, 'Heights(ft.)'],{CssStyle:'fontFamily:Oswald',fontSize:function(){return Math.round(14*brd1.canvasWidth/500.)}, fixed:true});
  	brd1.create('text', [-13.625, -1.5, function(){return ''+ (v[0]).toFixed(2)}],{ fixed:true, CssStyle:'fontFamily:Oswald',fontSize:function(){return Math.round(14*brd1.canvasWidth/500.)}});
  	brd1.create('text', [-10.875, -1.5, function(){return ''+ (v[1]).toFixed(2)}],{ fixed:true, CssStyle:'fontFamily:Oswald',fontSize:function(){return Math.round(14*brd1.canvasWidth/500.)}});
  	brd1.create('text', [-8.125, -1.5, function(){return ''+ (v[2]).toFixed(2)}],{ fixed:true,CssStyle:'fontFamily:Oswald',fontSize:function(){return Math.round(14*brd1.canvasWidth/500.)}});
  	brd1.create('text', [-5.375, -1.5, function(){return ''+ (v[3]).toFixed(2)}],{ fixed:true, CssStyle:'fontFamily:Oswald',fontSize:function(){return Math.round(14*brd1.canvasWidth/500.)}});
  	brd1.create('text', [-2.625, -1.5, function(){return ''+ (v[4]).toFixed(2)}],{ fixed:true, CssStyle:'fontFamily:Oswald',fontSize:function(){return Math.round(14*brd1.canvasWidth/500.)}});
  	brd1.create('text', [-13.5, 7.5, 'Mean &mu; (ft.)'],{CssStyle:'fontFamily:Oswald', color:'red', fontSize:function(){return Math.round(14*brd1.canvasWidth/500.)}, fixed:true});
  	brd1.create('text', [-5, 7.5, function(){return ''+ (mu()).toFixed(2)}],{fixed:true, CssStyle:'fontFamily:Oswald', color:'red', fontSize:function(){return Math.round(14*brd1.canvasWidth/500.)}});
  	brd1.create('text', [-13.5, 6., 'Standard Deviation &sigma; (ft.)'],{CssStyle:'fontFamily:Oswald',color:'blue', fontSize:function(){return Math.round(14*brd1.canvasWidth/500.)}, fixed:true});
  	brd1.create('text', [-5, 6., function(){return ''+ (JXG.Math.Statistics.sd(v)).toFixed(2)}],{ fixed:true, CssStyle:'fontFamily:Oswald', color:'blue', fontSize:function(){return Math.round(14*brd1.canvasWidth/500.)}});
  	brd1.create('text', [-13.5, 4.5, 'Median (ft.)'],{CssStyle:'fontFamily:Oswald',color:'green', fontSize:function(){return Math.round(14*brd1.canvasWidth/500.)}, fixed:true});
  	brd1.create('text', [-5, 4.5, function(){return ''+ (JXG.Math.Statistics.median(v)).toFixed(2)}],{ fixed:true, CssStyle:'fontFamily:Oswald', color:'green', fontSize:function(){return Math.round(14*brd1.canvasWidth/500.)}});
  	var func =brd1.create('functiongraph',[function(x){return (2.5/(sig()+0.01)/Math.sqrt(2*Math.PI))*Math.exp(-0.5*(x+mu())*(x+mu())/(sig()+0.01)/(sig()+0.01))}, 0, -4],{strokeColor:'red', strokeWidth:2});
  	var t = brd1.create('transform', [-Math.PI/2], {type: 'rotate'});
  	t.bindTo(func);
  	brd1.create('line',[[0, function(){return mu()}],[function(){return 2/sig()/Math.sqrt(2*Math.PI)}, function(){return mu()}]], {strokeWidth:1, dash:1});
  	var mean =brd1.create('image', ['/assets/random.svg', [6, function(){return mu()}],[1.25, 1.25]], {opacity:1, fixed:true});
  	mean.setLabel('Mean')
  	var std =brd1.create('image', ['/assets/random.svg', [2, function(){return mu()+sig()}],[1.25, 1.25]], {opacity:1, rotate:90, fixed:true});
  	std.setLabel('Spread')
  	mean.label.setAttribute({offset:[0, 50], CssStyle:'fontFamily:Oswald', fontSize:12});
  	std.label.setAttribute({offset:[0, 50], CssStyle:'fontFamily:Oswald', fontSize:12});
  */
      }
}
export default Boxes;
